var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['form-field', { disabled: _vm.disabled }],style:(_vm.blockStyle)},[(_vm.label)?_c('label',{staticClass:"title",attrs:{"for":_vm.myid}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(
            !_vm.readonly &&
                (_vm.required ||
                    _vm.decimals > 0 ||
                    _vm.min > Number.MIN_SAFE_INTEGER ||
                    _vm.max < Number.MAX_SAFE_INTEGER)
        )?_c('div',{staticClass:"restrict"},[(_vm.required)?_c('div',{class:[
                'restrict-item',
                _vm.checkRequired ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" 必 ")]):_vm._e(),_c('div',{class:[
                'restrict-item',
                _vm.checkDecimals && _vm.checkNumber
                    ? 'checked-ok'
                    : 'checked-error'
            ]},[(_vm.decimals > 0)?_c('span',[_vm._v("."+_vm._s(_vm.decimals)+"桁")]):_c('span',[_vm._v("整数")])])]):_vm._e(),_c('div',{style:(_vm.fieldStyle)},[_c('div',{staticClass:"form-field-input op-textfield"},[(_vm.readonly)?_c('div',{staticClass:"op-textfield-inner input-number readonly-field"},[(_vm.prefix)?_c('span',[_vm._v(_vm._s(_vm.prefix))]):_vm._e(),_vm._v(" "+_vm._s(_vm._f("amount")(_vm.textModel,0, _vm.decimals))+" "),(_vm.suffix)?_c('span',[_vm._v(_vm._s(_vm.suffix))]):_vm._e()]):_c('div',[(_vm.prefix)?_c('span',{class:['prefix', { presuf: _vm.suffix }]},[_vm._v(_vm._s(_vm.prefix))]):(_vm.suffix)?_c('span',{staticClass:"presuf",staticStyle:{"display":"none"}}):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textModel),expression:"textModel"}],staticClass:"op-textfield-inner input-number",style:([_vm.suffixStyle, _vm.prefixStyle]),attrs:{"type":"text","id":_vm.myid,"max":_vm.max,"min":_vm.min,"maxlength":_vm.initMaxLength(),"autocomplete":"off","disabled":_vm.disabled},domProps:{"value":(_vm.textModel)},on:{"change":_vm.inputChange,"input":[function($event){if($event.target.composing)return;_vm.textModel=$event.target.value},_vm.formatInput]}}),(_vm.suffix)?_c('span',{staticClass:"suffix"},[_vm._v(_vm._s(_vm.suffix))]):_vm._e(),_c('button',{staticClass:"op-textfield-clear-button",attrs:{"type":"button","tabindex":"-1","disabled":_vm.disabled},on:{"click":function($event){_vm.textModel = null}}},[_vm._v(" × ")])]),(!_vm.readonly)?_c('div',{class:[
                    'charscounter',
                    _vm.checkValueRange ? 'checked-ok' : 'checked-error'
                ]},[(_vm.min > Number.MIN_SAFE_INTEGER)?_c('span',[_vm._v(_vm._s(_vm._f("amount")(_vm.min)))]):_vm._e(),(
                        _vm.min > Number.MIN_SAFE_INTEGER ||
                            _vm.max < Number.MAX_SAFE_INTEGER
                    )?_c('span',[_vm._v(" ～ ")]):_vm._e(),(_vm.max < Number.MAX_SAFE_INTEGER)?_c('span',[_vm._v(_vm._s(_vm._f("amount")(_vm.max)))]):_vm._e()]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }