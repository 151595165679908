<template>
    <div>
        <OpSelect
            v-model="dataModel"
            :suggest="suggestDept"
            :suggestValue="suggestDeptValue"
            :suggestKey="suggestDeptKey"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            :disabled="disabled"
            :readonly="readonly"
            :maxwidth="maxwidth"
            :version="version"
            @changed-valid="changedValid"
            ref="input"
        />
    </div>
</template>

<script>
export default {
    name: "OpSelectDepartment",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Object,
        required: Boolean,
        name: String,
        id: String,
        disabled: Boolean,
        type: String,
        label: String,
        maxwidth: String,
        div: String,
        readonly: Boolean,
        referenceDate: Date
    },
    data: function() {
        return {
            dataModel: this.inputModel,
            modelChange: false,
            version: 0,
            listversion: 0,
            mylist: []
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            if (value == "") {
                value = null;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        },
        referenceDate: function() {
            this.version++;
        }
    },
    methods: {
        focus: function() {
            this.$refs.input.focus();
        },
        suggestDept: async function(query, result) {
            if (this.mylist.length < 1 || this.version != this.listversion) {
                await this.loadmylist();
                this.listversion = this.version;
            }
            let ret = -1;
            result.length = 0;
            let mx = this.mylist.length;
            for (let i = 0; i < mx; i++) {
                const d = this.mylist[i];
                result.push(d);
                if (ret < 0 && this.matchedDept(d, query)) {
                    ret = i;
                }
            }
            return ret;
        },
        suggestDeptValue: function(dept) {
            let ret = "";
            if (dept != null && dept.department_code != undefined) {
                ret = dept.department_code + " ";
                // const gg = dept.department_div === "0";
                if (this.type == "long") {
                    ret += dept.department_name_long;
                } else {
                    ret += dept.department_name_short;
                }
                // if (gg) ret += " ＊";
            }
            return ret;
        },
        matchedDept: function(dept, query) {
            let qs = query.split(" ");
            let ret = false;
            let cols = [
                "department_code",
                "department_name_short",
                "department_name_long"
            ];

            for (let q of qs) {
                let r = false;
                for (let c of cols) {
                    r = this.includeVal(dept[c], q);
                    if (r) break;
                }
                ret = r;
                if (!ret) {
                    break;
                }
            }
            return ret;
        },
        includeVal: function(val, q) {
            return val && val.includes(q);
        },
        suggestDeptKey: function(dept) {
            return dept.department_id;
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        },
        loadmylist: async function(func) {
            let refdate = new Date();
            if (this.referenceDate) {
                refdate = this.referenceDate;
            }
            await this.loaddeptlist_nocache(refdate, dt => {
                if (this.div) {
                    this.mylist = dt.filter(dept => {
                        if (this.div < 0) {
                            return true;
                        }
                        return dept.department_div == this.div;
                    });
                } else {
                    this.mylist = dt;
                }
                if (func) {
                    func();
                }
            });
        }
    },
};
</script>
