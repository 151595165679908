<template>
    <div :class="['form-field', { disabled: disabled }]" :style="blockStyle">
        <label class="title" v-if="label">{{ label }}</label>
        <div :style="fieldStyle">
            <div class="form-field-input op-textfield">
                <div class="readonly-field">
                    {{ text }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpLabelField",
    data: function() {
        return {
            myid: this.id,
            fieldStyle: {
                width: "100%"
            },
            blockStyle: {
                maxWidth: this.maxwidth,
                width: "100%"
            }
        };
    },
    props: {
        maxwidth: String,
        label: String,
        disabled: Boolean,
        text: String
    }
};
</script>
