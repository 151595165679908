<script>
import OpSelectCustomer from "@/components/OpSelectCustomer.vue";
import OpSelectDepartment from "@/components/OpSelectDepartment.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import OpNumberTextField from "@/components/OpNumberTextField.vue";
import OpLabelField from "@/components/OpLabelField.vue";

// const axios = require("axios");

export default {
    name: "EditorMixin",
    props: {
        model: Object,
        oppo: Object,
        mode: String
    },
    components: {
        OpSelectCustomer,
        OpSelectDepartment,
        OpCheckbox,
        OpNumberTextField,
        OpLabelField
    },
    data: function() {
        return {
            currentStep: 0,
            error_columns: new Set(),
            hasErrors: true,
            requesting: false
        };
    },
    computed: {},
    methods: {
        copyPartIdCodeName: function(obj, sourceprefix, groupprefix) {
            let gp = groupprefix;
            return this.copyPartObject(
                obj,
                sourceprefix,
                gp + "id",
                gp + "code",
                gp + "name_short",
                gp + "name_long"
            );
        },
        copyPartObject: function(obj, sourceprefix, ...params) {
            let ret = {};
            for (const p of params) {
                ret[p] = obj[sourceprefix + p];
            }
            return ret;
        },
        close: function() {
            this.$emit("fin");
        },
        isUpdate: function() {
            return this.mode == "update";
        },
        changedValid: function(name, isvalid) {
            if (isvalid) {
                if (this.error_columns.has(name)) {
                    this.error_columns.delete(name);
                }
            } else {
                if (!this.error_columns.has(name)) {
                    this.error_columns.add(name);
                }
            }
            this.hasErrors = this.error_columns.size > 0;
        },
        doCancel: function() {
            this.$emit("fin");
        },
        // putData: function(url, data, func) {
        //     let cdata = this.convertToRequestData(data);
        //     this.requesting = true;
        //     axios
        //         .put(url, cdata, func)
        //         .then(re => {
        //             let rdata = this.convertFromResponseData(re.data);
        //             // this.currentStep = 2;
        //             if (func instanceof Function) func(rdata);
        //             this.$emit("updated", rdata);
        //         })
        //         .catch(err => {
        //             if (err.response) {
        //                 alert(err.response);
        //             }
        //         })
        //         .then(() => {
        //             this.requesting = false;
        //         });
        // },
        // postData: function(url, data, func) {
        //     let cdata = this.convertToRequestData(data);
        //     this.requesting = true;
        //     axios
        //         .post(url, cdata)
        //         .then(re => {
        //             let rdata = this.convertFromResponseData(re.data);
        //             // this.currentStep = 2;
        //             if (func instanceof Function) func(rdata);
        //             this.$emit("updated", rdata);
        //         })
        //         .catch(err => {
        //             if (err.response) {
        //                 alert(err.response);
        //             }
        //         })
        //         .then(() => {
        //             this.requesting = false;
        //         });
        // },
        setStep: function(step) {
            if (this.currentStep != step) {
                this.currentStep = step;
                this.$emit("step", step);
                let wd = document.querySelector(".innerframe .content");
                if (wd) {
                    wd.scrollTo(0, 0);
                }
            }
        },
        isStep: function(step) {
            return this.currentStep == step;
        }
    },
    created: function() {}
};
</script>

<style></style>
