<template>
    <div class="op-select-customer">
        <OpSelect
            v-model="dataModel"
            :suggest="suggestCust"
            :suggestValue="suggestCustValue"
            :suggestKey="suggestCustKey"
            :required="required"
            :name="name"
            :id="id"
            :label="label"
            :version="version"
            @changed-valid="changedValid"
        />
    </div>
</template>

<script>
import DateUtils from "@/DateUtils";

const axios = require("axios");

export default {
    name: "OpSelectCustomer",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Object,
        required: Boolean,
        name: String,
        id: String,
        label: String,
        referenceDate: Date
    },
    data: function() {
        return {
            dataModel: { ...this.inputModel },
            modelChange: false,
            version: 0
        };
    },
    watch: {
        dataModel: function(value) {
            if (this.modelChange) {
                this.modelChange = false;
                return;
            }
            if (value == "") {
                value = null;
            }
            this.$emit("input", value);
        },
        inputModel: function(val) {
            this.modelChange = true;
            this.dataModel = { ...val };
        },
        referenceDate: function() {
            this.version++;
        }
    },
    methods: {
        suggestCust: async function(query, result, valk) {
            result.length = 0;
            await this.loadcustlist(
                query,
                function(res) {
                    result.length = 0;
                    for (const c of res) {
                        result.push(c);
                    }
                    // extrafunc();
                },
                valk
            );
        },
        suggestCustValue: function(cust) {
            let ret = "";
            if (cust && cust.customer_code != undefined) {
                ret = cust.customer_code + " " + cust.customer_name_short;
            }
            return ret;
        },
        suggestCustKey: function(cust) {
            return cust.customer_id;
        },
        changedValid: function(name, flag) {
            this.$emit("changed-valid", name, flag);
        },
        loadcustlist: async function(query, func, valk) {
            const cancelTokenKey = "custwords";
            let que = query.trim();

            let state = this.$store.state.customerlist;
            let dtm = state.delayTimerMap;

            // リクエスト処理実行キャンセル処理
            if (!valk && dtm.has(cancelTokenKey)) {
                clearTimeout(dtm.get(cancelTokenKey));
                dtm.delete(cancelTokenKey);
            }

            // リクエストキャンセル処理
            let cdt = this.cancelTokenDict;
            let cancel = cdt[cancelTokenKey];
            if (!valk && (cancel != undefined || cancel != null)) {
                cancel();
                cdt[cancelTokenKey] = null;
            }


            // リクエスト処理
            let req = async () => {
                let refdate = DateUtils.getNowDate();
                if (this.referenceDate) {
                    refdate = this.referenceDate;
                }
                let rdate = DateUtils.toRequestDate(refdate);
                let url = this.makeurl("cust", {
                    shwords: que,
                    limit: 20,
                    rdate: rdate
                });
                const CancelToken = axios.CancelToken;
                await axios
                    .get(url, {
                        cancelToken: new CancelToken(function executor(c) {
                            cdt[cancelTokenKey] = c;
                        })
                    })
                    .then(response => {
                        let rd = response.data;
                        let custlist = [];
                        // custlist.length = 0;
                        let idlist = [];
                        this.mergeHeadDataToObj(
                            custlist,
                            rd.header,
                            rd.data,
                            null,
                            obj => {
                                let cid = obj.customer_id;
                                idlist.push(cid);
                            }
                        );

                        func(custlist);
                    })
                    .catch(function(thrown) {
                        if (axios.isCancel(thrown)) {
                            console.log("Request canceled", thrown.message);
                        } else {
                            // handle error
                        }
                    });
            };
            if (valk) {
                await req();
            } else {
                dtm.set(cancelTokenKey, setTimeout(req, 250));
            }
        }
    }
};
</script>
