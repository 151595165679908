<template>
    <div :class="['form-field', { disabled: disabled }]" :style="blockStyle">
        <label class="title" v-if="label" :for="myid">{{ label }}</label>
        <div
            class="restrict"
            v-if="
                !readonly &&
                    (required ||
                        decimals > 0 ||
                        min > Number.MIN_SAFE_INTEGER ||
                        max < Number.MAX_SAFE_INTEGER)
            "
        >
            <div
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
                v-if="required"
            >
                必
            </div>
            <div
                :class="[
                    'restrict-item',
                    checkDecimals && checkNumber
                        ? 'checked-ok'
                        : 'checked-error'
                ]"
            >
                <span v-if="decimals > 0">.{{ decimals }}桁</span>
                <span v-else>整数</span>
            </div>
        </div>
        <div :style="fieldStyle">
            <div class="form-field-input op-textfield">
                <div
                    v-if="readonly"
                    class="op-textfield-inner input-number readonly-field"
                >
                    <span v-if="prefix">{{ prefix }}</span>
                    {{ textModel | amount(0, decimals) }}
                    <span v-if="suffix">{{ suffix }}</span>
                </div>
                <div v-else>
                    <span
                        v-if="prefix"
                        :class="['prefix', { presuf: suffix }]"
                        >{{ prefix }}</span
                    >
                    <span
                        v-else-if="suffix"
                        class="presuf"
                        style="display:none;"
                    ></span>
                    <input
                        class="op-textfield-inner input-number"
                        type="text"
                        @change="inputChange"
                        v-model="textModel"
                        :id="myid"
                        :max="max"
                        :min="min"
                        :maxlength="initMaxLength()"
                        autocomplete="off"
                        :style="[suffixStyle, prefixStyle]"
                        @input="formatInput"
                        :disabled="disabled"
                    />
                    <span v-if="suffix" class="suffix">{{ suffix }}</span>
                    <button
                        type="button"
                        class="op-textfield-clear-button"
                        @click="textModel = null"
                        tabindex="-1"
                        :disabled="disabled"
                    >
                        ×
                    </button>
                </div>
                <div
                    v-if="!readonly"
                    :class="[
                        'charscounter',
                        checkValueRange ? 'checked-ok' : 'checked-error'
                    ]"
                >
                    <span v-if="min > Number.MIN_SAFE_INTEGER">{{
                        min | amount
                    }}</span>
                    <span
                        v-if="
                            min > Number.MIN_SAFE_INTEGER ||
                                max < Number.MAX_SAFE_INTEGER
                        "
                    >
                        ～
                    </span>
                    <span v-if="max < Number.MAX_SAFE_INTEGER">{{
                        max | amount
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpNumberTextField",
    model: {
        prop: "inputModel",
        event: "input"
    },
    data: function() {
        return {
            textModel: this.initModel(this.model),

            errors: new Set(),
            validflag: true,
            myid: this.id,
            // fieldStyle: {
            //     maxWidth: this.maxwidth,
            //     width: "100%"
            // },
            prefixStyle: {
                paddingLeft: this.prefixwidth
                    ? "calc(" + this.prefixwidth + " + 10px)"
                    : "15px"
            },
            suffixStyle: {
                paddingRight: this.suffixwidth
                    ? "calc(" + this.suffixwidth + " + 30px)"
                    : "30px"
            },
            fieldStyle: {
                maxWidth: this.disabledAutowidth
                    ? "auto"
                    : "calc(" +
                      this.initMaxLength() * 1.2 +
                      "ex + " +
                      (this.prefixwidth
                          ? this.prefixwidth + " + 15px"
                          : "15px") +
                      " + " +
                      (this.suffixwidth
                          ? this.suffixwidth + " + 30px"
                          : "30px") +
                      ")",
                width: "100%"
            },
            blockStyle: {
                width: "100%"
            }
        };
    },
    props: {
        inputModel: [String, Number],
        required: Boolean,
        disabled: Boolean,
        max: { default: Number.MAX_SAFE_INTEGER },
        min: { default: Number.MIN_SAFE_INTEGER },
        default: { default: 0, type: Number },
        maxwidth: String,
        name: String,
        label: String,
        id: String,
        decimals: { default: 0, type: Number },
        readonly: Boolean,
        prefix: String,
        prefixwidth: String,
        suffix: String,
        suffixwidth: String,
        disabledAutowidth: Boolean
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        inputModel: function(val) {
            let value = this.formatValue(val); //val.replace(/,/g, "");
            if (this.textModel != value) {
                this.textModel = value;
            }
        },
        disabled: function(value) {
            if (value) {
                this.validflag = true;
            } else {
                this._checkRequired();
                this._checkDecimals();
                this._checkValueRange();
            }
        }
    },
    methods: {
        formatInput: function() {
            // this.textModel = this.formatValue(this.textModel);
            let ret = this.textModel;
            if (ret !== null && ret !== undefined) {
                ret = ret.replace(/,/g, "");
            } else {
                ret = this.default;
            }
            this.$emit("liveinput", ret);
        },
        formatValue: function(value) {
            let ret = "";
            let num = NaN;
            if (typeof value == "number") {
                num = value;
            } else if (typeof value == "string") {
                num = new Number(value.replace(/,/g, ""));
            }
            if (!isNaN(num)) {
                ret = num.toLocaleString();
            } else {
                ret = value;
            }
            return ret;
        },
        initModel: function() {
            return this.formatValue(this.inputModel);
        },
        initMaxLength: function() {
            let ret = 0;
            if (this.min < 0) {
                ret += 1;
            }
            if (this.decimals > 0) {
                ret += this.decimals + 1;
            }
            let str = Math.abs(this.max).toString();
            let sx = str.indexOf(".");
            let ss = 0;
            if (sx > 0) {
                ss = sx;
            } else {
                ss = str.length;
            }
            ss += Math.floor((ss + 1) / 3);
            ret += ss;
            return ret;
        },
        inputChange: function() {
            if (this.validflag) {
                let ret = this.textModel;
                if (ret !== null && ret !== undefined) {
                    ret = ret.replace(/,/g, "");
                }
                this.$emit("input", ret);
                this.textModel = this.formatValue(this.textModel, true);
            }
        },
        settingErrors: function(name, isvalid) {
            let cols = this.errors;
            if (isvalid) {
                if (cols.has(name)) {
                    cols.delete(name);
                }
            } else {
                if (!cols.has(name)) {
                    cols.add(name);
                }
            }
            this.validflag = cols.size < 1;
        },

        _checkRequired() {
            let ret = true;
            if (this.required) {
                ret =
                    this.textModel !== undefined &&
                    this.textModel !== null &&
                    this.textModel !== "";
            }
            this.settingErrors("required", ret);
            return ret;
        },
        _checkValidNumber() {
            let ret = true;
            if (
                this.textModel !== undefined &&
                this.textModel !== null &&
                this.textModel !== ""
            ) {
                ret = /^-?[0-9][0-9,]*(\.\d*)?$/.test(this.textModel);
            }
            this.settingErrors("number", ret);
            return ret;
        },
        _checkDecimals() {
            let ret = true;
            if (this._checkValidNumber() && typeof this.textModel == "string") {
                let ix = this.textModel.indexOf(".");
                if (ix > -1) {
                    //let rp = this.textModel.substring(0, ix);
                    let dp = this.textModel.substring(ix + 1);
                    ret = dp.length <= this.decimals;
                } else {
                    ret = true;
                }
            }
            this.settingErrors("decimals", ret);
            return ret;
        },
        _checkValueRange() {
            let ret = true;
            if (this.textModel && this._checkValidNumber()) {
                let vt = 0;
                let vi = 0;
                let vx = 0;
                if (this.decimals > 0) {
                    let val = this.textModel.replace(/,/g, "");
                    let ttt = this.shiftDecimalPoint(val);
                    let mit = this.shiftDecimalPoint(this.min);
                    let mxt = this.shiftDecimalPoint(this.max);

                    let mx = ttt[1];
                    if (mx < mit[1]) mx = mit[1];
                    if (mx < mxt[1]) mx = mxt[1];

                    vt = ttt[0] * Math.pow(10, mx - ttt[1]);
                    vi = mit[0] * Math.pow(10, mx - mit[1]);
                    vx = mxt[0] * Math.pow(10, mx - mxt[1]);
                } else {
                    if (this.textModel) {
                        let val = this.textModel.replace(/,/g, "");
                        vt = Number.parseInt(val, 10);
                    }
                    vi = Number.parseInt(this.min, 10);
                    vx = Number.parseInt(this.max, 10);
                }
                if (vi > vt) {
                    ret = false;
                }
                if (vx < vt) {
                    ret = false;
                }
            }
            this.settingErrors("valueRange", ret);
            return ret;
        },
        shiftDecimalPoint: function(value) {
            let v = 0;
            let p = 0;
            let val = "" + value;

            let ix = val.indexOf(".");
            if (ix > -1) {
                let rp = val.substring(0, ix);
                let dp = val.substring(ix + 1);
                p = dp.length;
                v = Number.parseInt("" + rp + dp, 10);
            } else {
                v = Number.parseInt(val, 10);
            }

            return [v, p];
        }
    },
    mounted: function() {
        this._checkRequired();
        this._checkValueRange();
        this._checkDecimals();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        },
        checkValueRange() {
            return this._checkValueRange();
        },
        checkDecimals() {
            return this._checkDecimals();
        },
        checkNumber() {
            return this._checkValidNumber();
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
